.transparent {
    opacity: 0;
}

ul,
li,
p,
h1,
h2,
h3 {
    margin: 0;
    padding: 0;
}

html {
    line-height: 1;
}
ul {
    list-style-type: none;
}
input {
    outline: none;
    padding: 0;
    border: 0;
}

body {
    min-width: 1280px;
    padding: 0;
    margin: 0;
    border: none;
    font-family: -apple-system, "Hiragino Sans GB", Roboto, "Droid Sans", "Microsoft YaHei", "Microsoft YaHei UI", "Microsoft JhengHei UI", STHeiti, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Helvetica Neue", sans-serif;
    background-image: url(http://wapcdn.thecover.cn/fengmianhao/1.0.0/img/bg.jpg);
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
#page_login {
    background-image: none;
}
#content {
    width: 1004px;
    padding: 0 18px;
    margin: 0 auto;
    height: 100%;
    background-color: rgb(232,232,234);
}
#footer {
    color: rgba(255, 255, 255, 0.7);
    font-size: 12px;
    text-align: center;
    vertical-align: bottom;
    line-height: 20px;
    width: 100%;
    padding: 0px 0 50px 0;
}

#footer a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
}

.my-btn {
    -webkit-transition: background-color 0.2s ease-in;
    -moz-transition: background-color 0.2s ease-in;
    -ms-transition: background-color 0.2s ease-in;
    -o-transition: background-color 0.2s ease-in;
    transition: background-color 0.2s ease-in;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    box-shadow: 1px 1px 5px 1px #87808066;
}

.my-btn:hover {
    cursor: pointer;
}

input[type="password"] {
    font-family: monospace;
}

.help-block.input-error {
    color: #f44336;
    display: block !important;
}

.modal-content .modal-header{padding: 15px 20px 0px; }  .bootstrap-dialog .bootstrap-dialog-title{  }
.bootstrap-dialog .icon-spin{
    -moz-animation: spin 1s infinite linear;
    -o-animation: spin 1s infinite linear;
    -webkit-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
}
.modal-content .modal-body{
    padding-top: 0 !important;
}
.bootstrap-dialog-body .remind-icon{
    font-size: 50px;
    display: inline-block;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    width: 10%;
}
.bootstrap-dialog-body .remind-text{
    height: 50px;
    display: inline-block;
    line-height: 50px;
    color: #777;
    width: 80%;
    font-size: 1rem;
}
.bootstrap-dialog-footer-buttons button{
    color: #fff !important;
    border-radius: 20px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
.bootstrap-dialog-footer-buttons button.btn-default{
    background: #ccc;
}
.bootstrap-dialog-footer-buttons button.btn-primary{
    background: #2dc2e8;
}
.bootstrap-dialog-footer-buttons button.btn-default:hover{
    background: #ccc;
}
.bootstrap-dialog-footer-buttons button.btn-primary:hover{
    background: #66CAE3 !important;
}
.modal-footer{
    padding: 10px !important;
    border-top: 1px solid #ddd !important;
}